<!--
 * @Author: Tang
 * @Date: 2022-07-22 16:09:42
 * @LastEditTime: 2023-09-25 13:51:39
 * @FilePath: /CGWebsite/src/views/home/index.vue
-->
<template>
  <div style="background: #fff">
    <div class="head-box flex_between">
      <div class="logo-box">
        <img class="logo" src="../../assets/img/logo.png" alt="畅购天下" />
      </div>
      <div class="nav-box flexalign" id="navbar">
        <span
          class="nav-item"
          v-for="(item, id) in navList"
          :key="id"
          @click="toArea(item.index)"
        >
          {{ item.title }}
        </span>
        <span class="nav-item" @click="toArcticle">新闻动态</span>
      </div>
    </div>
    <div class="main-box">
      <div>
        <img
          style="margin-bottom: 168px"
          src="../../assets/img/home_bg.png"
          fit="contain"
        />
        <div class="video-box">
          <Video class="video-player" :mp4Pic="poster" :mp4Url="mp4Url"></Video>
          <img src="../../assets/img/o2o_bg.png" fit="contain" alt="畅购O2O" />
        </div>
        <div style="position: relative">
          <img src="../../assets/img/contact_bg.png" fit="contain" />
          <div class="beian-box" @click="toBeian"></div>
        </div>
      </div>
      <!-- <div v-else>
        <article class="article-box">
          <header>
            <h2 class="title">
              【网络安全宣传】杭州警方依法处置三起扰乱公共秩序的网络谣言
            </h2>
            <p>
              近期，根据公安部网络谣言打击整治专项行动的总体部署，浙江省杭州市公安局网安部门持续加强对网络谣言的打击力度，坚决遏制网络谣言高发频发态势。
            </p>
            <p>
              案例一 依法查处颜某某编造“一女子遭四男子奸杀”网络谣言
              2023年01月03日，网民颜某某在某网站平台发布“一女子遭四男子奸杀”信息并附两张女子浮尸图片。
              经查，颜某某为博取关注、吸引流量，编造“一女子遭四男子奸杀”的谣言信息引发网民关注讨论，造成社会不良影响。
              杭州临平警方依据《中华人民共和国治安管理处罚法》第二十五条规定，对颜某某处以行政拘留8日的处罚。
            </p>
            <p>
              案例二 依法查处葛某某编造“杭州2名消防员在火灾中牺牲”网络谣言
              2023年01月08日，网民葛某某在短视频平台发布杭州某地火情现场视频，并配文“#杭州后市街定安苑着火#杭州2名消防员在火灾中牺牲”。
              经查，葛某某为博取关注、吸引流量，东拼西凑新闻内容编造“杭州2名消防员在火灾中牺牲”谣言信息，引发网民关注讨论，造成社会不良影响。杭州上城警方依据《中华人民共和国治安管理处罚法》第二十五条规定，对葛某某处以行政罚款300元的处罚。
            </p>
            <p>
              案例三 依法查处华某某编造“西湖区成为杭州唯一双减改革区”网络谣言
              2024年1月11日，华某某在某平台发布“西湖区成为杭州唯一双减改革区”的文章。经向杭州市教育局核实，系混淆概念，为不实信息。
              经查，华某某为博取关注、吸引流量，编造“西湖区成为杭州唯一双减改革区”的谣言信息引发网民关注讨论，造成社会不良影响。
              杭州拱墅警方依据《中华人民共和国治安管理处罚法》第二十五条规定，对华某某处以行政罚款200元的处罚。
            </p>
            <p>
              提醒
              互联网不是法外之地，网民应保持理性思考，增强防范意识及信息甄别能力，不造谣、不信谣、不传谣。
            </p>
          </header>
        </article>
      </div> -->
    </div>
  </div>
</template>

<script>
import Video from "../components/videoDialog.vue";
export default {
  props: {},
  data() {
    return {
      active: 0, // 当前激活的导航索引
      scrollTop: "", //滚动距离
      scrollHeight: 0, //当前滚轮高度
      metaHeight: 15, //最小滚动距离
      metaTime: 5, //最小执行时间
      targetHeight: 0, //目标区域滚轮高度
      navList: [
        {
          id: "nav1",
          title: "首页",
          index: 1,
        },
        {
          id: "nav2",
          title: "畅购天下",
          index: 2,
        },
        {
          id: "nav3",
          title: "联系我们",
          index: 3,
        },
      ],
      fade: true,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            withCredentials: false,
            type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "@/assets/video/SPCvideo.mp4",
          },
        ],
        controls: true,
        hls: true,
        poster: "@/assets/img/video_cover.png", // 你的封面地址
        width: 600, // 播放器宽度
        height: 600,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          progressControl: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: true,
          fullscreenToggle: true, // 全屏按钮
        },
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } },
      },
      poster: require("@/assets/img/video_cover.png"),
      mp4Url: require("@/assets/video/o2ovideo.mp4"),
      // fileType: "m4v",
      showArticleFlag: false, //是否显示文章
    };
  },
  computed: {},
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {},
  methods: {
    handleScroll() {
      var scrollTop = document.documentElement.scrollTop;
      this.scrollHeight = scrollTop;
      console.log("当前滚轮高度:", this.scrollHeight); // 使用后记得注释掉哦
    },
    toArea(index) {
      // this.showArticleFlag = false;
      // 这里的index是左侧导航栏传的参数，是不同区域设定好的索引值
      switch (
        index // 匹配不同区域的滚轮高度
      ) {
        case 1: //区域一
          this.targetHeight = 74; //这里将第一步获取到的滚轮高度取整
          break;
        case 2: //区域二
          this.targetHeight = 1466;
          break;
        case 3: //区域三
          this.targetHeight = 2973;
          break;
        default: //默认：区域一
          this.targetHeight = 1466;
          break;
      }
      // 判断执行情况
      // 当指定区域和当前滚动条位置一致时
      if (
        (document.documentElement.scrollTop === 74 &&
          this.targetHeight === 74) ||
        (document.documentElement.scrollTop === 1466 &&
          this.targetHeight === 1466) ||
        (document.documentElement.scrollTop === 2973 &&
          this.targetHeight === 2973)
      ) {
        console.log("已经到达该区域了哦");
      }
      // 当指定区域高度大于当前滚动条位置时（即目标区在当前滚轮的下方）
      else if (this.targetHeight > this.scrollHeight) {
        // 计算高度差
        let x = this.targetHeight - this.scrollHeight;
        // 先加上余数，保证高度差能整除设定的最小移动单位
        document.documentElement.scrollTop += x % this.metaHeight;
        x -= x % this.metaHeight;
        const goto = setInterval(() => {
          // 建立执行操作的定时器
          document.documentElement.scrollTop += this.metaHeight; // 控制移动滚动条
          x -= this.metaHeight; // 缩减高度差
          if (x == 0) {
            // 到达指定位置后清除定时器
            clearInterval(goto); //清除定时器
          }
        }, this.metaTime);
      }
      // 当指定区域高度小于当前滚动条位置时（即目标区在当前滚轮的上方）
      else {
        // 计算高度差
        let x = this.scrollHeight - this.targetHeight;
        // 先减去余数，保证高度差能整除设定的最小移动单位
        document.documentElement.scrollTop -= x % this.metaHeight;
        x -= x % this.metaHeight;
        const goto = setInterval(() => {
          document.documentElement.scrollTop -= this.metaHeight;
          x -= this.metaHeight;
          if (x == 0) {
            clearInterval(goto); //清除定时器
          }
        }, 1);
      }
    },
    showArticle() {
      this.showArticleFlag = !this.showArticleFlag;
    },
    toArcticle() {
      window.open(
        "https://h5.cnbuyers.cn/#/otherPages/pages/help/news",
        "_blank"
      );
    },
    toBeian() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
  },
  components: {
    Video,
  },
};
</script>

<style scoped lang="scss">
.head-box {
  width: 100%;
  height: 74px;
  padding: 8px 296px;
  background: rgba($color: #ffffff, $alpha: 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .logo-box {
    width: 126px;
    height: 60px;
    .logo {
      width: 100%;
      height: 100%;
    }
  }
  .nav-box {
    // width: 38%;
    .nav-item {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      margin: 0 40px;
      cursor: pointer;
      background: linear-gradient(to right, #333333, #999999) no-repeat right
        bottom;
      background-size: 0 2px;
      transition: background-size 500ms;
    }
    .nav-item:hover {
      background-position-x: left;
      background-size: 100% 2px;
    }
  }
}
.main-box {
  padding-top: 74px;
  img {
    width: 100%;
    height: 100%;
  }
  .video-box {
    position: relative;
    margin-bottom: 70px;
    .video-player {
      width: 830px;
      height: 428px;
      position: absolute;
      left: 318px;
      top: 23.4rem;
    }
  }
  .video-box2 {
    position: relative;
    .video-player {
      width: 830px;
      height: 428px;
      position: absolute;
      left: 318px;
      top: 43.2rem !important;
    }
  }
  .article-box {
    padding: 80px 240px;
    .title {
      font-size: 36px;
      text-align: center;
      padding-bottom: 40px;
    }
    p {
      margin: 20px 0;
      font-size: 24px;
      line-height: 1.5;
      text-indent: 48px;
    }
  }
  .beian-box {
    width: 664px;
    height: 40px;
    position: absolute;
    left: 32%;
    bottom: 80px;
    z-index: 99;
    cursor: pointer;
  }
}
</style>
